import React, {Fragment, memo} from 'react'
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    UncontrolledAccordion,
    UncontrolledPopover,
    UncontrolledTooltip,
} from 'reactstrap'
import PropTypes from 'prop-types'
import {updateLayerAttribute} from '../../../../context/SelectedVariantContext/action'
import NumberFormat from 'react-number-format'
import {CompactPicker} from 'react-color'
import {CMYK_COLORS} from '../../../../context/SelectedVariantContext/constants'

const TextEffect = memo(({layer, dispatch}) => {
    const _toggleSwitch = (event) => {
        const {name, checked} = event.target
        layer[name] = checked
        dispatch(updateLayerAttribute(layer))
    }

    const _changeEffects = (effect) => {
        const {name, value} = effect
        layer[name] = value
        dispatch(updateLayerAttribute(layer))
    }

    const _handleStrokePicker = (color) => {
        if (!layer || layer.isLock) return
        layer.stroke = color.hex
        dispatch(updateLayerAttribute(layer))
    }

    const _handleDropShadowPicker = (color) => {
        if (!layer || layer.isLock) return
        layer.shadowColor = color.hex
        dispatch(updateLayerAttribute(layer))
    }

    return (
        <UncontrolledAccordion className="Effects ImageEffects">
            <AccordionItem>
                <AccordionHeader targetId="imageEffect">Effects</AccordionHeader>
                <AccordionBody accordionId="imageEffect">
                    <FormGroup>
                        <Label for="pattern" className={'fw-500'}>Special effect</Label>
                        <Input
                            className={'fs-14'}
                            id="pattern"
                            name="hasStroke"
                            type="select"
                            value={layer.name}
                            onChange={(event) => {
                                const {name, value} = event.target
                                layer[name] = value
                                dispatch(updateLayerAttribute(layer))
                            }}
                        >
                            <option value={false}>None</option>
                            <option value={true}>Stroke</option>
                        </Input>
                    </FormGroup>
                        <div className="row mb-3">
                            <div className="col-sm-5">
                                <Input
                                    className={'fs-14'}
                                    id="pattern"
                                    name="strokeType"
                                    type="select"
                                    onChange={(event) => {
                                        const {name, value} = event.target
                                        layer[name] = value
                                        dispatch(updateLayerAttribute(layer))
                                    }}
                                >
                                    <option value="inside">Inside</option>
                                    <option value="outside">Outside</option>
                                </Input>
                            </div>
                            <div className="col-sm-3">
                                <NumberFormat
                                    className="form-control text-right fs-14"
                                    value={layer.strokeWidth}
                                    disabled={layer.isLock}
                                    decimalScale={2}
                                    autoComplete="off"
                                    allowLeadingZeros={false}
                                    onValueChange={(event) => {
                                        const {floatValue} = event
                                        _changeEffects({value: floatValue, name: 'strokeWidth'})
                                    }}
                                />
                            </div>
                            <div className="col-sm-4">
                                <div className="StrokeInputWrapper">
                                    <div id="StrokeColorPicker" className="me-2">
                                        <div className="Icon" style={{backgroundColor: layer?.stroke || '#000000'}}></div>

                                        <UncontrolledPopover trigger="legacy" placement="left" target="StrokeColorPicker">
                                            <CompactPicker
                                                color={layer.stroke || '#000000'}
                                                colors={CMYK_COLORS}
                                                onChange={(color) => _handleStrokePicker(color)}
                                            />
                                        </UncontrolledPopover>
                                        <UncontrolledTooltip target="StrokeColorPicker">
                                            Change stroke color
                                        </UncontrolledTooltip>
                                    </div>
                                    <Input
                                        type="text"
                                        value={layer.stroke}
                                        disabled={layer.isLock}
                                        className="form-control text-right fs-14"
                                        onChange={(event) => {
                                            const {value} = event.target
                                            _changeEffects({value, name: 'stroke'})
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <FormGroup>
                            <Label for="pattern" className={'fw-500'}>Warp</Label>
                            <Input
                                className={'fs-14'}
                                id="pattern"
                                name="hasArc"
                                type="select"
                                onChange={(event) => {
                                    const {name, value} = event.target
                                    layer[name] = value
                                    dispatch(updateLayerAttribute(layer))
                                }}
                            >
                                <option value={false}>None</option>
                                <option value={true}>Arced text</option>
                            </Input>
                        </FormGroup>
                        <div className="d-flex align-items-center">
                            <Label className="switch-label me-2 fw-500" htmlFor="switchPattern">
                                Drop shadow
                            </Label>
                            <FormGroup switch>
                                <Input
                                    id="switchPattern"
                                    type="switch"
                                    name="hasDropShadow"
                                    checked={layer.hasDropShadow}
                                    onChange={_toggleSwitch}
                                    className="switch-input"
                                />
                            </FormGroup>
                        </div>
                        {layer.hasDropShadow && (
                            <Fragment>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Label className="mb-0 fs-14">Opacity</Label>
                                        <InputGroup>
                                            <NumberFormat
                                                className="form-control fs-14"
                                                value={layer.hasDropShadow ? layer.shadowOpacity : 0}
                                                disabled={layer.isLock}
                                                decimalScale={2}
                                                autoComplete="off"
                                                allowLeadingZeros={false}
                                                onValueChange={(event) => {
                                                    const {floatValue} = event
                                                    _changeEffects({value: floatValue, name: 'shadowOpacity'})
                                                }}
                                            />
                                            <InputGroupText>%</InputGroupText>
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <Label className="mb-0 fs-14">Size</Label>
                                        <NumberFormat
                                            className="form-control fs-14"
                                            // value={layer.hasDropShadow ? layer. : 0}
                                            disabled={layer.isLock}
                                            decimalScale={2}
                                            autoComplete="off"
                                            allowLeadingZeros={false}
                                            onValueChange={(event) => {
                                                // const {floatValue} = event
                                                // layer.shadowOffsetX = floatValue
                                                // layer.shadowOffsetY = floatValue
                                                // dispatch(updateLayerAttribute(layer))
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <Label className="mb-0 fs-14">Blur</Label>
                                        <NumberFormat
                                            className="form-control fs-14"
                                            value={layer.hasDropShadow ? layer.shadowBlur : 0}
                                            disabled={layer.isLock}
                                            decimalScale={2}
                                            autoComplete="off"
                                            allowLeadingZeros={false}
                                            onValueChange={(event) => {
                                                const {floatValue} = event
                                                _changeEffects({value: floatValue, name: 'shadowBlur'})
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <Label className="mb-0 opacity-0">Shadow color</Label>
                                        <div className="DropShadowInputWrapper">
                                            <div id="DropShadowColorPicker" className="me-2">
                                                <div
                                                    className="Icon"
                                                    style={{backgroundColor: layer?.shadowColor || '#000000'}}
                                                ></div>

                                                <UncontrolledPopover
                                                    trigger="legacy"
                                                    placement="left"
                                                    target="DropShadowColorPicker"
                                                >
                                                    <CompactPicker
                                                        color={layer.shadowColor || '#000000'}
                                                        colors={CMYK_COLORS}
                                                        onChange={_handleDropShadowPicker}
                                                    />
                                                </UncontrolledPopover>
                                                <UncontrolledTooltip target="DropShadowColorPicker">
                                                    Change stroke color
                                                </UncontrolledTooltip>
                                            </div>
                                            <Input
                                                type="text"
                                                value={layer.shadowColor}
                                                disabled={layer.isLock}
                                                className="form-control text-right fs-14"
                                                onChange={(event) => {
                                                    const {value} = event.target
                                                    _changeEffects({value, name: 'shadowColor'})
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                </AccordionBody>
            </AccordionItem>
        </UncontrolledAccordion>
    )
})

TextEffect.propTypes = {
    layer: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
}

export default TextEffect

